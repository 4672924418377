<template>
  <div class="block">
    <el-timeline>
      <el-timeline-item
        v-for="(history, index) in histories"
        :key="index"
        :type="logType"
        color='#0bbd87'
        hollow="true"
        >
        <div v-if="history.flag==='startup'">
            <span style="font-weight:bold;color:#409eff"> {{history.taskLocalVariables.flow}}：【{{history.name}}】</span>
        </div>
        <div v-if="history.deleteReason">
            <span style="font-weight:bold;color:#409eff"> {{history.taskLocalVariables.flow}}：【终止流程】</span>
        </div>
        <div v-if="history.flag !='startup' && !history.deleteReason">
            <span style="font-weight:bold;color:#409eff"> {{history.taskLocalVariables.flow}}：【{{history.name}}】{{history.name==='品质部'?'发码':'审核'}} - 
              {{history.endTime?(history.taskLocalVariables.approved?'同意':'退回'):'待处理'}}</span>
        </div>
        <el-row style="margin-top:10px;">
          <el-col>
            <span style="font-weight:bold;">处理人：</span>
            <span v-if="history.flag==='startup'||history.deleteReason">{{this.auditor.auditor}}</span>
            <span v-else>{{history.taskLocalVariables.name[history.assignee]}}</span>
          </el-col>
          <el-col style="margin-top:10px;">
            <span style="font-weight:bold;">处理时间：</span>
            <span>{{history.endTime}}</span>
          </el-col>
          <el-col style="margin-top:10px;" v-if="history.flag !=='startup' && !history.deleteReason">
            <span style="font-weight:bold;">审核意见：</span>
            <span>{{history.endTime?(history.taskLocalVariables.note && history.taskLocalVariables.note.length>0?history.taskLocalVariables.note:'无'):''}}</span>
          </el-col>
          <el-col style="margin-top:10px;" v-if="history.flag !=='startup' && history.deleteReason">
            <span style="font-weight:bold;">终止原因：</span>
            <span>{{history.endTime?(history.deleteReason && history.deleteReason.length>0?history.deleteReason:'无'):''}}</span>
          </el-col>
        </el-row>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import Api from '@/apis/api'
export default {
    data(){
        return {
            logType:'success',
            histories:[],
            auditor: undefined
        }
    },
    props: {
      /**
       * 数据源
       * */
      sourceApi: {
        type: Api,
        default: null
      },
      formData:{
        type: Object,
        default: function(){
          return {}
        }
      },
      isComplete:{
        type:Boolean,
        default: false
      }
    },
    created(){
      this.getHistories(this.formData)
    },
    methods:{
      getHistories(data){
        this.sourceApi.getHistories(data.id).then(res => {
          this.histories = res.data
          if(Array.isArray(data.applyAuditors)&&data.applyAuditors.length> 0){
              var auditor = data.applyAuditors[0]
              this.auditor = auditor
              this.histories.unshift(
                  {
                    assignee:auditor.auditor,
                    name:'发起流程',
                    endTime: auditor.createTime,
                    flag:'startup',
                    taskLocalVariables:{
                        flow:'标签申请'
                    }
                  }
              )
              if(this.isComplete){
                var endAuditor = data.applyAuditors[data.applyAuditors.length - 1]
                this.histories.push(
                  {
                    assignee:endAuditor.auditor,
                    name:'流程结束',
                    endTime: endAuditor.createTime,
                    flag:'startup',
                    taskLocalVariables:{
                        flow:'标签申请'
                    }
                  }
                )
              }
          }
          
        })
      }
    }
}
</script>

<style>

</style>